import axios from "axios";
import React, { useState, useEffect } from "react";

export default function HisUpload({ handleClose, handleUpload, pic }) {
  const [imageHistory, setImageHistory] = useState([]);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fecthListImg = async () => {
      const { data } = await axios.get(
        `https://api.funface.online/images/${userId}?type=video`,
        {
          headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      console.log(data.image_links_video);
      setImageHistory(data.image_links_video);
    };
    fecthListImg();
  }, []);

  return (
    <div className=" top-0 right-0 left-0 bottom-0 fixed grid place-items-center">
      <div className="w-[720px] h-fit flex flex-col bg-white p-4 rounded-3xl gap-10">
        <h1 className="text-3xl font-bold">Upload your face</h1>
        <div className="flex w-full min-h-[200px] flex-row gap-3 overflow-x-scroll">
          {imageHistory &&
            imageHistory.length &&
            imageHistory.map((item, index) => {
              return (
                <img
                  key={index}
                  src={item}
                  alt="img"
                  onClick={()=>handleUpload(item)}
                  className="w-[160px] h-[200px] object-cover border rounded-lg flex-shrink-0"
                />
              );
            })}
        </div>

        <div className="flex flex-row justify-end gap-4">
          <label htmlFor={pic} className="cursor-pointer px-[24px] py-[12px] bg-black text-white block w-fit rounded-full" onClick={handleUpload}>
            Upload new image
          </label>
          <span className="cursor-pointer px-[24px] py-[12px] bg-black text-white block w-fit rounded-full" onClick={handleClose}>
            Close
          </span>
        </div>
      </div>
    </div>
  );
}
