import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./Videos.css";

import { getListVideos } from "../../services/video.service";
import useLoading from "../../hooks/useLoading";
import Paginations from "../../components/Paginations";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import filterApp from "../../components/image/filter-app.png";
import Header from "../../components/Header/Header";
import { VideoItem } from "../../components/VideoItem/VideoItem";
import { useNavigate, useParams } from "react-router";

const Videos = () => {
  const params = useParams();

  const [category, setCategory] = useState(params['cate']);
  const [totalPages, setTotalPages] = useState(null);
  const [listVideo, setListVideo] = useState([]);
  const [page, setPage] = useState(params['page']);

  const navi = useNavigate(); 

  const { setIsLoading } = useLoading();

  const [categoryList, setCategoryList] = useState([]);

  const getData = async (page,cate) => {
    setIsLoading(true);
    try {
      await getListVideos(page, cate)
      .then(res=>{
        setListVideo(res.data.list_video);
        console.log(res.data.list_video);
        setTotalPages(res.data.total_page);
        console.log(res.data.total_page);
        if (res.status !== 200)
          throw new Error("Error while getting videos");
  
        if (res.data === "exceed the number of pages!!!") {
          setPage(1);
          throw new Error("Exceeds the number of pages");
        }
      }).catch(error => console.log(error))

      

    } catch (err) {
      toast.error(err.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    const getCategory = async ()=>{
      try {
        axios.get('https://api.funface.online/list_category')
        .then((res)=>{
          console.log(res.data);
          setCategoryList(res.data);
        });

      }catch(err){
        console.log(err);
      }
    }

    getCategory();
    // getData(params['page'],params['cate']);
   
  },[])

  useEffect(() => {
    navi(`/videos/${page}/${category}`)
    getData(page,category);
  }, [category, page]);

  const handleChangeCategory = (e) => {
    setPage(1);
    setCategory(e.target.value);
  };

  useEffect(() => {
    console.log(listVideo);
  })

  return (
    <>
      <Header
        data={{
          title: "videos",
          myCollection: "videos/my-videos",
          download: true,
        }}
      />
      <div className="max-h-[100vh] overflow-y-scroll rounded-lg py-4">
        <div className="flex flex-col gap-8">
          <div className="flex justify-between items-center rounded-lg">
            <div className="flex items-center rounded-lg bg-green-500 overflow-hidden text-white text-4xl px-3">
              <div>
                <img src={filterApp} alt="Filter" />
              </div>

              <Select
                sx={{
                  width: "100%",
                  fontSize: { xs: 16, md: 18 },
                  color: "white",
                  ".MuiOutlinedInput-notchedOutline": { borderStyle: "none" },
                  ".MuiSelect-icon": {
                    color: "white",
                    fontSize: { xs: 16, md: 18 },
                  },
                }}
                value={category}
                onChange={(e)=>handleChangeCategory(e)}
              >
                <MenuItem value={0}>Category</MenuItem>
                {categoryList.map((item,index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="flex gap-4 items-center">
              <span className="text-white text-2xl sm:text-4xl font-semibold">
                Page:
              </span>
              <Paginations
                page={page}
                setPage={setPage}
                totalPages={totalPages}
              />
            </div>
          </div>

          <ul className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-[10px]">
            {listVideo &&
              listVideo.map((item,index) => (
                <VideoItem {...item} type="video goc" key={index} />
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Videos;
